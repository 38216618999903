import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';

export enum WidgetActionTypes {
  LoadWidgets = '[Widget] Load Widgets',
  AddWidget = '[Widget] Add Widget',
  UpsertWidget = '[Widget] Upsert Widget',
  AddWidgets = '[Widget] Add Widgets',
  UpsertWidgets = '[Widget] Upsert Widgets',
  UpdateWidget = '[Widget] Update Widget',
  UpdateWidgets = '[Widget] Update Widgets',
  DeleteWidget = '[Widget] Delete Widget',
  DeleteWidgets = '[Widget] Delete Widgets',
  ClearWidgets = '[Widget] Clear Widgets',
}

export class LoadWidgets implements Action {
  readonly type = WidgetActionTypes.LoadWidgets;

  constructor(public payload: { widgets: any[] }) {}
}

export class AddWidget implements Action {
  readonly type = WidgetActionTypes.AddWidget;

  constructor(public payload: { widget: any }) {}
}

export class UpsertWidget implements Action {
  readonly type = WidgetActionTypes.UpsertWidget;

  constructor(public payload: { widget: any }) {}
}

export class AddWidgets implements Action {
  readonly type = WidgetActionTypes.AddWidgets;

  constructor(public payload: { widgets: any[] }) {}
}

export class UpsertWidgets implements Action {
  readonly type = WidgetActionTypes.UpsertWidgets;

  constructor(public payload: { widgets: any[] }) {}
}

export class UpdateWidget implements Action {
  readonly type = WidgetActionTypes.UpdateWidget;

  constructor(public payload: { widget: Update<any> }) {}
}

export class UpdateWidgets implements Action {
  readonly type = WidgetActionTypes.UpdateWidgets;

  constructor(public payload: { widgets: Update<any>[] }) {}
}

export class DeleteWidget implements Action {
  readonly type = WidgetActionTypes.DeleteWidget;

  constructor(public payload: { id: string }) {}
}

export class DeleteWidgets implements Action {
  readonly type = WidgetActionTypes.DeleteWidgets;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearWidgets implements Action {
  readonly type = WidgetActionTypes.ClearWidgets;
}

export type WidgetActions =
  | LoadWidgets
  | AddWidget
  | UpsertWidget
  | AddWidgets
  | UpsertWidgets
  | UpdateWidget
  | UpdateWidgets
  | DeleteWidget
  | DeleteWidgets
  | ClearWidgets;
